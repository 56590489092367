import axios from 'axios';
import { Mutex } from 'async-mutex'
import TokenService from 'src/utils/tokenService';
import { appInsights, severityLevel } from 'src/utils/appInsight';

const baseUrl: string = process.env.REACT_APP_API_URL;

interface HeaderTypeInterFace {
    Accept: string;
    'Content-Type': string;
    'Access-Control-Allow-Origin': string;
    Authorization?: string;
    'x-api-key'?: string;
    'client-id'?: number;
}

interface ResponseInterface {
    data: object | object[] | string[];
    status: string | number;
}

const defaultHeaders: HeaderTypeInterFace = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*'
};

const grantType = 'refresh_token';
const loginApiUrl = 'User/userLogin';

const api = async function (
    endpoint: string,
    method: string,
    params: object = {},
    additionalHeaders: object = {},
    url: string = baseUrl
): Promise<any> {
    const fullUrl: string = url + endpoint;
    
    const accessToken: string = TokenService.getLocalAccessToken();
    const config: any = {
        headers: { ...defaultHeaders,'Authorization': `Bearer ${accessToken}`, ...additionalHeaders },
    };

    if (['post', 'put', 'patch'].includes(method.toLowerCase())) {
        config.data = params;
    } else if (method.toLowerCase() === 'get') {
        config.params = params;
    } else if (method.toLowerCase() === 'delete') {
        config.data = params;
    }

    try {
        const response = await axios.request({
            url: fullUrl,
            method: method.toLowerCase(),
            ...config,
        });
        return { data: response.data, status: response.status };
    } catch (error) {
        appInsights.trackException({
            exception: error,
            severityLevel: severityLevel.Error,
        });

        if (error.response) {
            if (error.response.status === 401 && !(method.toLocaleLowerCase() === 'post' && endpoint === loginApiUrl)) {
                const newAccessToken = await handleExpiredToken(error);
                if (newAccessToken) {
                    return await retryApiCall(endpoint, method, params, additionalHeaders);
                } else {
                    TokenService.logOut();
                    window.location.href = '/';
                    throw new Error('Unauthorized access');
                }
            } else {
                return {
                    data: error.response.data,
                    status: error.response.status,
                };
            }
        }

        throw error;
    }
};

const handleExpiredToken = async function (error: any) {
    const loginUrl: string = baseUrl + loginApiUrl;
    const originalConfig = error.config;
    if (originalConfig.url !== loginUrl && error.response) {
        if (error.response.status === 401) {
            return refreshAccessToken();
        }
    }
};
// module singleton
// With mutex so we don't spam the refresh token route
const authMutex = new Mutex()
async function refreshAccessToken(): Promise<string | null> {
    const loginUrl = baseUrl + loginApiUrl;
    const refreshToken = TokenService.getLocalRefreshToken();

    if (!refreshToken) {
        return null;
    }
    // Check if there is a refresh request going on
    if (!authMutex.isLocked()){
    // Lock acquired; no other refresh operations ongoing
        const release = await authMutex.acquire();
        try {
            const response = await axios.post(loginUrl, {
                grant_type: grantType,
                refresh_token: refreshToken,
                username: '',
                password: '',
                rememberMe: false
            });
    
            if (response.status !== 200) {
                return null;
            }
    
            const { token: newAccessToken, refreshToken: newRefreshToken } =
                response.data;
    
            TokenService.updateLocalAccessToken(newAccessToken);
            TokenService.updateLocalRefreshToken(newRefreshToken);
    
            return newAccessToken;
        } catch (error: any) {
            appInsights.trackException({
                exception: error,
                severityLevel: severityLevel.Error
            });
            return null;
        }
        finally {
            release()
        }
    }
    else {
        // wait for the current refresh operation to finish and return the new token
        await authMutex.waitForUnlock();
        return TokenService.getLocalAccessToken();
    }
}
const retryApiCall = async function (
    endpoint: string,
    method: string,
    params: object = {},
    additionalHeaders: object = {}
) {
    return await api(endpoint, method, params, additionalHeaders);
};

export { api, baseUrl, refreshAccessToken };
